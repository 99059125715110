import { useMutation } from "@tanstack/react-query";
import { useBeforeUnload } from "react-use";
import { toast as toastBase } from "sonner";

export const useToastBlockNavigation = (block = true) => {
  const mut = useMutation({
    mutationKey: ["toast-block-navigation"],
    mutationFn: ([promise, config]: Parameters<
      (typeof toastBase)["promise"]
    >) => {
      return new Promise((res) => {
        toastBase.promise(promise, {
          ...config,
          //eslint-disable-next-line
          //@ts-ignore
          success: (data) => {
            res(data);
            if (typeof config?.success === "function") {
              return config?.success?.(data) ?? "Success";
            }
            return config?.success ?? "Success";
          },
        });
      });
    },
  });
  useBeforeUnload(
    mut.isLoading && block,
    "You have unsaved changes, are you sure you want to leave?"
  );
  const { mutateAsync: toastPromise, mutate: toast, ...rest } = mut;
  return {
    toastPromise,
    toast,
    ...rest,
  };
};
