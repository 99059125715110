/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { LanguageSelector } from "@components/LanguageSelector";
import {
  LoadingModal,
  type LoadingModalHandle,
} from "@components/LoadingModal";
import OpenTicketLogo from "@components/OpenTicketLogo";
import TicketingBackdrop from "@components/TicketingBackdrop";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@components/ui/AlertDialog";
import { Button } from "@components/ui/Button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/Form";
import { Input } from "@components/ui/Input";
import { Separator } from "@components/ui/Separator";
import { trpc } from "@utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import { useConnectWithEmail, useSiweAuth } from "@hooks/auth";
import { useTheme } from "@hooks/theme";

import OpenTicketLogoCompact from "@components/OpenTicketLogoCompact";
import { useIsMounted } from "@hooks/isServer";
import { useToastBlockNavigation } from "@hooks/useToastBlockNavigation";
import { useAccount, useConnect, useWagmi } from "@hooks/useWagmi";
import { generateAuthOptions } from "@pages/api/auth/[...nextauth]";
import { applicationChain } from "@utils/applicationChain";
import { type GetServerSideProps } from "next";
import { getServerSession } from "next-auth";
import { useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { toast } from "sonner";
import { z } from "zod";

const emailForm = z.object({
  email: z
    .string()
    .email()
    .transform((email) => {
      const trimmedLowerEmail = email.trim().toLowerCase();
      const validEmail = trimmedLowerEmail
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return validEmail;
    }),
});

const EmailSSOButton = ({
  status,
}: {
  status: "loading" | "error" | "success" | "idle";
}) => {
  const form = useForm<z.infer<typeof emailForm>>({
    resolver: zodResolver(emailForm),
    defaultValues: {
      email: "",
    },
  });
  const { mutateAsync: connect } = useConnectWithEmail();
  const { mutateAsync: userExist } = trpc.user.exists.useMutation();
  const t = useTranslations("Ticketing.pages.login");
  const term = useTranslations("Terms");
  const [open, setOpen] = useState(false);
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <button
          className="mb-4 flex w-full items-center justify-center rounded-full bg-gray-100/10 py-3 ring-4 ring-gray-700/30 disabled:opacity-50 disabled:hover:cursor-not-allowed"
          disabled={status === "loading"}
        >
          <h3 className="relative flex items-center gap-2 text-lg">Email</h3>
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("emailRequired")}</AlertDialogTitle>
        </AlertDialogHeader>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit(async ({ email }) => {
                const doesUserExist = await userExist({
                  id: email,
                });
                const isValidProvider = ["unknown", "email"].includes(
                  doesUserExist?.userProvider ?? ""
                );
                if (doesUserExist && !isValidProvider) {
                  form.setError("email", {
                    type: "custom",
                    message: t("userExistsWithEmail"),
                  });
                  return;
                }
                setOpen(false);
                return connect(email);
              })(e);
            }}
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="sr-only">Email</FormLabel>
                  <FormControl>
                    <Input placeholder="email@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                  <FormDescription>
                    {t("clickConfirmDescription")}
                  </FormDescription>
                </FormItem>
              )}
            />
            <AlertDialogFooter className="mt-3">
              <AlertDialogCancel>{term("cancel")}</AlertDialogCancel>
              <Button>{term("continue")}</Button>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const Login = () => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleTheme } = useTheme();
  const { wagmi } = useWagmi();
  const loadingRef = useRef<LoadingModalHandle>(null);
  const t = useTranslations("Ticketing.pages.login");
  const isMounted = useIsMounted();
  const {
    mutate: handleSiweSignature,
    status: siweStatus,
    reset: resetSiwe,
  } = useSiweAuth({
    onError() {
      toast.error(t("signInError"));
    },
  });
  const account = useAccount();
  const { mutateAsync: connect, status } = useConnect({
    onMutate() {
      resetSiwe();
    },
    async onSuccess(data) {
      if (!data) return;
      if (data.chainId !== applicationChain.id) {
        await wagmi?.core.switchChain(wagmi.config, {
          chainId: applicationChain.id,
        });
      }
    },
  });
  useEffect(() => {
    if (
      account.isConnected &&
      !account.isConnecting &&
      siweStatus === "idle" &&
      status === "success"
    ) {
      handleSiweSignature(account);
    }
  }, [account, handleSiweSignature, siweStatus, status]);
  const { toastPromise } = useToastBlockNavigation(false);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  const withToast = <T extends unknown>(fn: () => Promise<T>) => {
    toastPromise([
      fn(),
      {
        loading: t("signingIn"),
        success: t("signInSuccess"),
        error: t("signInError"),
      },
    ]).catch(() => {
      return;
    });
  };
  return (
    <TicketingBackdrop
      className="grid h-full grid-cols-1 text-card-foreground lg:grid-cols-2"
      position="top left"
    >
      <div
        role="button"
        onClick={handleTheme}
        className="js-dark-mode-trigger group absolute right-4 top-4 z-40 ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-stone-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
        </svg>
      </div>
      <div className="relative hidden flex-col  items-center justify-center lg:flex">
        <OpenTicketLogo
          width={350}
          className="fill-black dark:fill-stone-100"
        />
      </div>
      <div className="relative flex items-center justify-center bg-card px-4 text-card-foreground">
        <div className="flex w-full flex-col items-center justify-center sm:w-[424px]">
          <h1 className="relative flex items-center justify-center text-center text-4xl font-semibold dark:text-white">
            <div className="absolute -top-[220%] block lg:hidden">
              <OpenTicketLogo
                width={250}
                className="hidden fill-black dark:fill-stone-100 md:block"
              />
              <OpenTicketLogoCompact
                width={50}
                className="block fill-black dark:fill-stone-100 md:hidden"
              />
            </div>
            {t("signIn")}
          </h1>

          <h2 className="mb-4 text-center text-lg">{t("selectProvider")}</h2>
          <Separator className="mb-8" />

          <button
            className="mb-4 flex w-full items-center justify-center rounded-full bg-gray-100/10 py-3 ring-4 ring-gray-700/30 disabled:opacity-50 disabled:hover:cursor-not-allowed"
            disabled={status === "loading"}
            onClick={() => {
              if (!wagmi) return;
              withToast(() =>
                connect({
                  connector: "google",
                })
              );
            }}
          >
            <h3 className="relative flex items-center gap-2 text-lg">
              <FcGoogle size={"2ex"} className="absolute -left-8" />
              Google
            </h3>
          </button>
          <button
            className="mb-4 flex w-full items-center justify-center rounded-full bg-gray-100/10 py-3 ring-4 ring-gray-700/30 disabled:opacity-50 disabled:hover:cursor-not-allowed"
            disabled={status === "loading"}
            onClick={() => {
              if (!wagmi) return;
              withToast(() =>
                connect({
                  connector: "facebook",
                })
              );
            }}
          >
            <h3 className="relative flex items-center gap-2 text-lg">
              <FaFacebookF
                size={"2ex"}
                className="absolute -left-8 text-blue-900 dark:text-white"
              />
              Facebook
            </h3>
          </button>
          <EmailSSOButton status={status} />
          {isMounted &&
            typeof window !== "undefined" &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            typeof window.ethereum !== "undefined" && (
              <button
                className="mb-4 flex w-full items-center justify-center rounded-full bg-gray-100/10 py-3 ring-4 ring-gray-700/30 disabled:opacity-50 disabled:hover:cursor-not-allowed"
                disabled={status === "loading"}
                onClick={() => {
                  if (!wagmi) return;
                  withToast(() =>
                    connect({
                      connector: "injected",
                    })
                  );
                }}
              >
                <h3 className="relative flex items-center gap-2 text-lg">
                  Metamask
                </h3>
              </button>
            )}
        </div>
        <LanguageSelector className="absolute bottom-4" />
      </div>
      <LoadingModal ref={loadingRef} />
    </TicketingBackdrop>
  );
};
export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const session = await getServerSession(
      context.req,
      context.res,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      generateAuthOptions(context.req)
    );
    if (session) {
      return {
        redirect: {
          destination: "/",
          permanent: false,
        },
      };
    }

    return {
      props: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        messages: (
          await import(`common/messages/${context.locale as string}.json`)
        ).default,
      },
    };
  } catch (err) {
    console.error(`Errored`, err);
    return {
      notFound: true,
    };
  }
};

export default Login;
